.head {
	padding-top: 30px;
}
.info {
	display: flex;
	flex-direction: column;
    gap: 10px;
	.part {
		border: 1px solid #5f8bb48f;
		padding: 10px;
    }
    .en{
        flex-basis: 50%;
        margin-right: 10px;
    }
	.ar {
        direction: rtl;
        flex-basis: 50%;
        margin-left: 10px;
	}
}
@media only screen and (max-width: 600px)  {
    .info {
        flex-direction: column;
        .en{
            margin-right: 0;
            margin-bottom: 20px;
        }
        .ar {
            margin-left: 0;
        }
    }
}
