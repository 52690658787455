@import-normalize;

.app {
	color: white;
	.menu {
		position: fixed;
		top: 0;
		z-index: 100;
		width: 100%;
		display: flex;
		justify-content: center;
		.menuItem {
            text-transform: capitalize;
            padding: 10px;
            margin: 10px;
            text-decoration: none;
            background-color: #5f8bb48f;
            border-radius: 15px;
            font-weight: bold;
            color: white;
            &:visited{
                color: white;
            }
            &:focus{
                color: white;
            }
            &:hover{
                color: black;
            }
            
        }
        @media only screen and (max-width: 500px)  {
            font-size: 12px;
        }
	}
	.home {
		background: url('./assets/images/home.png');
		background-size: cover;
		background-position: bottom;
		.homeInner {
			position: relative;
			height: 100%;
		}
		.background {
			height: 80%;
		}
		.logo {
			width: 20%;
			position: absolute;
			bottom: 7%;
			right: 5%;
		}
	}
	.footer{
		position: fixed;
		padding: 10px 0;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		img{
			cursor: pointer;
			margin: 0 10px;
		}
	}
}
