.container {
	display: flex;
    justify-content: center;
    margin-bottom: 200px;
}
.head {
	padding-top: 30px;
}

.firstAblum {
	margin-left: 10px;
}

.secondAlbum {
	margin-right: 10px;
}

@media only screen and (max-width: 600px)  {
    .container {
       flex-direction: column;
    }

    .firstAblum {
        margin-left: 0;
        margin-top: 10px;
    }
    
    .secondAlbum {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
