.container {
  display: flex;
}

.head {
  padding-top: 30px;
}

.newsletterInput {
  font-size: 15px;
  padding: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 9px;
}

.menuItem {
  padding: 10px;
  margin: 10px;
  font-size: 15px;
  text-decoration: none;
  background-color: #5f8bb48f;
  border-color: #5f8bb48f;
  border-radius: 15px;
  font-weight: bold;
  color: white;
  cursor: pointer;

  &:visited {
    color: white;
  }

  &:focus {
    color: white;
  }

  &:hover {
    color: black;
  }

}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .part {
    border: 1px solid #5f8bb48f;
    padding: 10px;
  }

  .en {
    flex-basis: 50%;
    margin-right: 10px;
  }

  .ar {
    direction: rtl;
    flex-basis: 50%;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .info {
    flex-direction: column;

    .en {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .ar {
      margin-left: 0;
    }
  }
}
